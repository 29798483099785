export enum ProposalMutation {
    SET_GROSS_MARGIN = 'PROPOSAL_SET_GROSS_MARGIN_MUTATION',
    SET_COST_PRICE = 'PROPOSAL_SET_COST_PRICE_MUTATION',
    SET_DISPLAY_LIST = 'PROPOSAL_SET_DISPLAY_LIST_MUTATION',
    SET_FORM_PROCESSING = 'PROPOSAL_SET_FORM_PROCESSING_MUTATION',
    SET_BLOCK_GROSS_MARGIN = 'PROPOSAL_SET_BLOCK_GROSS_MARGIN_MUTATION',
    SET_SALE_PRICES_ROWS = 'PROPOSAL_SET_SALE_PRICES_ROWS_MUTATION',
    SET_WEIGHT_BRACKETS = 'PROPOSAL_SET_WEIGHT_BRACKETS_MUTATION',
    SET_IS_LOADING = 'PROPOSAL_SET_IS_LOADING_MUTATION',
    REMOVE_SALE_PRICES_ROW = 'PROPOSAL_REMOVE_SALE_PRICES_ROW_MUTATION',
    SET_PROPOSAL_AVAILABLE_SUPPLIERS = 'PROPOSAL_SET_AVAILABLE_SUPPLIERS_MUTATION',
    SET_PROPOSAL_AVAILABLE_COUNTRIES = 'PROPOSAL_SET_AVAILABLE_COUNTRIES_MUTATION',
    SET_PROPOSAL_AVAILABLE_PRODUCTS = 'PROPOSAL_SET_AVAILABLE_PRODUCTS_MUTATION',
    SET_PROPOSAL_AVAILABLE_CONTRACTS = 'PROPOSAL_SET_AVAILABLE_CONTRACTS_MUTATION',
    SET_PROPOSAL_AVAILABLE_SERVICES = 'PROPOSAL_SET_AVAILABLE_SERVICES_MUTATION',
    ADD_PROPOSAL_SUPPLIERS_SECTION = 'PROPOSAL_ADD_SUPPLIERS_SECTION_MUTATION',
    REMOVE_PROPOSAL_SUPPLIERS_SECTION = 'PROPOSAL_REMOVE_SUPPLIERS_SECTION_MUTATION',
    SAVE_PROPOSAL_SUPPLIERS_SECTION = 'PROPOSAL_SAVE_SUPPLIERS_SECTION_MUTATION',
    CLEAR_PROPOSAL_SUPPLIERS_CHOICES = 'PROPOSAL_CLEAR_SUPPLIERS_CHOICES_MUTATION',
    SET_PROPOSAL = 'PROPOSAL_SET_PROPOSAL_MUTATION',
    CLEAR_PROPOSAL = 'PROPOSAL_CLEAR_PROPOSAL_MUTATION',
    CLEAR_SALE_PRICES = 'PROPOSAL_CLEAR_SALE_PRICES_MUTATION',
    SETUP_GROSS_MARGIN = 'PROPOSAL_SETUP_GROSS_MARGIN_MUTATION',
    SET_CONTENT_INTRO_TEXT = 'PROPOSAL_SET_CONTENT_INTRO_TEXT_MUTATION',
    SET_CONTENT_GENERAL_DISCLAIMER = 'PROPOSAL_SET_CONTENT_GENERAL_DISCLAIMER_MUTATION',
    SET_CONTENT_DISCLAIMERS = 'PROPOSAL_SET_CONTENT_DISCLAIMERS_MUTATION',
    SET_CONTENT_TERMS = 'PROPOSAL_SET_CONTENT_TERMS_MUTATION',
    SET_CONTENT_GDPR = 'PROPOSAL_SET_CONTENT_GDPR_MUTATION',
    SET_RETURNS_SALE_PRICES_ROWS = 'PROPOSAL_SET_RETURNS_SALE_PRICES_ROWS_MUTATION',
    SET_RETURNS_WEIGHT_BRACKETS = 'PROPOSAL_SET_RETURNS_WEIGHT_BRACKETS_MUTATION',
    SET_SURCHARGES_SALE_PRICES_ROWS = 'PROPOSAL_SET_SURCHARGES_SALE_PRICES_ROWS_MUTATION',
    ADD_CONTENT_PARAGRAPH = 'PROPOSAL_ADD_CONTENT_PARAGRAPH_MUTATION',
    CHANGE_PARAGRAPH = 'PROPOSAL_CHANGE_PARAGRAPH_MUTATION',
    REMOVE_PARAGRAPH = 'PROPOSAL_REMOVE_PARAGRAPH_MUTATION',
    SET_PROPOSAL_CONTENT = 'PROPOSAL_SET_PROPOSAL_CONTENT_MUTATION',
    SET_IS_PROCESSING = 'PROPOSAL_SET_IS_PROCESSING_MUTATION',
    SET_PROPOSAL_LIST = 'PROPOSAL_SET_PROPOSAL_LIST_MUTATION',
    SETUP_OUTBOUND_GROSS_MARGIN = 'PROPOSAL_SETUP_OUTBOUND_GROSS_MARGIN_MUTATION',
    SETUP_RETURNS_GROSS_MARGIN = 'PROPOSAL_SETUP_RETURNS_GROSS_MARGIN_MUTATION',
    SETUP_SURCHARGES_GROSS_MARGIN = 'PROPOSAL_SETUP_SURCHARGES_GROSS_MARGIN_MUTATION',
    SET_PROPOSAL_DESCRIPTION = 'PROPOSAL_SET_PROPOSAL_DESCRIPTION_MUTATION',
    SET_PROPOSAL_VALID_FROM = 'PROPOSAL_SET_PROPOSAL_VALID_FROM',
    SET_PROPOSAL_VALID_TO = 'PROPOSAL_SET_PROPOSAL_VALID_TO'
}
