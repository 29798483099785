
import { defineComponent, PropType } from 'vue';
import ProposalStatus from '@/components/proposal/ProposalStatus.vue';
import { ProposalStatus as ProposalStatusEnum } from '@/model/proposal-status';
import moment from 'moment';
import { ProposalCustomer } from '@/model/proposal-customer';
import ExpandButton from '@/components/ui/ExpandButton.vue';
import ProposalRowActions from '@/components/proposal/list/ProposalRowActions.vue';
import { mapGetters } from 'vuex';
import Modal from '@/components/ui/Modal.vue';
import { ProposalAction } from '@/store/proposal/proposal.action';

export default defineComponent({
  name: "ProposalRow",
  components: {Modal, ProposalRowActions, ExpandButton, ProposalStatus},
  props: {
    proposal: {
      required: true,
      type: Object as PropType<ProposalCustomer>
    }
  },
  data() {
    return {
      isExpanded: false,
      showWarningModal: false
    }
  },
  methods: {
    handleDeleteConfirmClick(): void {
      this
          .$store
          .dispatch(ProposalAction.DELETE_PROPOSAL, this.proposal.id)
          .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
    },
    handleProposalDelete(): void {
      this.showWarningModal = true;
    }
  },
  computed: {
    ...mapGetters(['isProposalEditable']),
    createdAt(): string {
      return moment(this.proposal.createdAt, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    sentAt(): string {
      if (!this.proposal.sentAt) {
        return '';
      }

      return moment(this.proposal.sentAt, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    approvedAt(): string {
      if (!this.proposal.approvedAt) {
        return '';
      }

      return moment(this.proposal.approvedAt, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    rejectedAt(): string {
      if (!this.proposal.rejectedAt) {
        return '';
      }

      return moment(this.proposal.rejectedAt, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    validFrom(): string {
      if (!this.proposal.validFrom) {
        return '';
      }

      return moment(this.proposal.validFrom, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    validTo(): string {
      if (!this.proposal.validTo) {
        return '';
      }

      return moment(this.proposal.validTo, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    updatedAt(): string {
      if (!this.proposal.updatedAt) {
        return '';
      }

      return moment(this.proposal.updatedAt, 'YYYY-MM-DD').format('DD.MM.YYYY');
    },
    status(): string {
      if (!this.proposal.status) {
        return ProposalStatusEnum.DRAFT;
      }

      return this.proposal.status;
    },
    changed(): boolean {
      return this.proposal.pricesChanged;
    },
    isProposalEditable(): boolean {
      return this.status === ProposalStatusEnum.DRAFT;
    }
  }
})
