import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        pagination: {
            count: {
                showing: 'Showing',
                of_rows: 'of {number} rows'
            },
            per_page: {
                show: 'Show',
                row_per_page: 'row per page'
            }
        },
        auth: {
            logout_modal: {
                content: 'Are you sure you want to log out?',
                title: 'Log Out'
            },
        },
        customer: {
            create: {
                notifications: {
                    save_success: 'Customer has been added'
                },
                buttons: {
                    proceed: 'Proceed',
                    save: 'Save'
                },
                header: 'New customer',
                destination_countries: {
                    title: 'Destination countries'
                },
                info: {
                    title: "Customer's info",
                    form: {
                        title: 'General',
                        caption: {
                            address: 'Address',
                            contact: 'Contact',
                            general: 'General'
                        },
                        label: {
                            name: 'Name',
                            tax: 'TAX Number',
                            vat: 'VAT',
                            vat_uk: 'VAT UK',
                            eori: 'EORI',
                            website: 'Website',
                            phone: 'Phone',
                            contact_name: 'Contact Name',
                            contact_position: 'Contact Position',
                            contact_email: 'Contact E-mail',
                            country: 'Country',
                            address: 'Address line',
                            zip_code: 'Zip Code',
                            city: 'City',
                            state: 'State',
                            parent_customer: 'Parent customer',
                            suppliers: 'Suppliers'
                        }                    
                    }
                }
            },
            details: {
                name: 'Name',
                email: 'E-mail',
                phone: 'Phone number',
                tax_id: 'TAX number',
                country_name: 'Country',
                address: 'Address',
                contact_name: 'Main Contact Name',
                contact_position: 'Contact Position',
                contact_email: 'Contact e-mail'
            },
            list: {
                header: {
                    title: 'Customers list',
                    menu: {
                        add: 'Add customer'
                    }
                },
                content: {
                    table: {
                        header: {
                            customer_id: 'Customer ID',
                            name: 'Name',
                            country: 'Country'
                        }
                    }
                },
                actions: {
                  export_xlsx: 'Export All Excel Approved Proposals for this year'
                }
            }
        },
        general: {
            ok: 'OK',
            save: 'Save',
            edit: 'Edit',
            search: 'Search',
            reset_filters: 'Reset filters',
            actions: 'Actions',
            description: 'Description',
            reset_sorting: 'Reset Sorting',
            filters: 'Filters',
            apply: 'Apply',
            sorting: 'Sort by',
            sort: 'Sort',
            filter: 'Filter',
            suppliers: 'Suppliers',
            saving: 'Saving ...',
            loading: 'Loading ...',
            select: 'Select',
            cancel: 'Cancel',
            attachments: {
                pdf: 'PDF'
            },
            button: {
                save: 'Save'
            },
            nav: {
                dashboard: 'Dashboard',
                proposals: 'Proposals',
                clients: 'Customers',
                price_lists: 'Price lists',
                log_out: 'Log Out',
                admin_panel: 'Admin Panel',
            },
            price: 'Price',
            currency_symbol: {
                USD: '$',
                EUR: '€',
                SEK: 'SEK',
                DKK: 'DKK'
            },
            notifications: {
                success: 'Success',
                error: 'Error',
                update: 'Update'
            }
        },
        proposal: {
            suppliers: {
                modal_form: {
                    title: 'Specify Suppliers',
                    add_button: 'Add Supplier',
                    remove_button: 'Remove Supplier',
                    section_title: '#{number} Supplier',
                    fields_labels: {
                        supplier: 'Supplier',
                        countries: 'Countries',
                        products: 'Products',
                        contracts: 'Contracts',
                        services: 'Services',
                    },
                    select_all: 'Select All',
                    apply: 'Apply'
                },
                button: 'Suppliers',
            },
            collective: {
                title: 'Set up collective Gross Margin',
                caution: 'Caution! When general GM is applied all changes that have been made in the tab will be lost.'
            },
            content: {
                heading: 'Manage Proposal Content',
                preview_pdf: 'Preview PDF',
                add_paragraph: 'Add paragraph',
                sections: {
                    intro_text: 'Intro Text',
                    disclaimers: 'Disclaimers',
                    general_disclaimer: 'General Disclaimer',
                    terms_conditions: 'Terms & Conditions',
                    gdpr: 'GDPR',
                    paragraph: 'Paragraph {number}'
                },
                paragraph: {
                    edit: 'Edit paragraph',
                    add: 'Add paragraph',
                    fields: {
                        title: 'Title',
                        text: 'Text'
                    }
                },
                disclaimers: {
                    title: 'Disclaimers',
                    edit_disclaimers: 'Edit disclaimers',
                    add_disclaimer: 'Add disclaimer'
                }
            },
            send: {
                title: 'Send Proposal',
                recipients: {
                    heading: 'Recipient e-mail address',
                    address: {
                        label: 'Contact email',
                        add_address: 'Add e-mail address'
                    }
                },
                message: {
                    message_heading: 'Message',
                    sent_from: 'Sent from: {from}',
                    attachments_heading: 'Attachments'
                },
                buttons: {
                    send: 'Send to the customer'
                },
                add_email: {
                    title: 'Add e-mail address',
                    email: 'E-mail'
                }
            },
            general: {
                gross_margin: 'Gross Margin',
                cost_prices: 'Cost Prices',
                country: 'Country',
                service: 'Service',
                product: 'Product',
                surcharge: 'Surcharge',
                contract: 'Contract',
                supplier: 'Supplier',
                weight_bracket_header: '<{weight}kg'
            },
            list: {
                header: {
                    title: 'Proposals',
                    menu: {
                        add: 'Add proposal'
                    }
                },
                content: {
                    warning: {
                      title: 'Delete proposal {id}',
                      content: 'Are you really want to delete a proposal? The proposal will be removed permanently'
                    },
                    table: {
                        header: {
                            client_name: 'Customer name',
                            proposal_number: 'Proposal number',
                            status: 'Status',
                            created_at: 'Created at',
                            sent_at: 'Sent at',
                            approved_at: 'Approved at',
                            rejected_at: 'Rejected at',
                            valid_from: 'Valid from',
                            valid_till: 'Valid till'
                        },
                        body: {
                            proposal_number: 'Proposal {number}',
                            last_edit: 'Last edit by {updatedBy} {updatedAt}',
                            actions: {
                                send: 'Awaiting acceptance',
                                xls: 'Export XLS',
                                pdf: 'Export PDF',
                                draft: 'Set as draft',
                                reject: 'Reject',
                                accept: 'Accept',
                                archive: 'Archive',
                                accepted: 'Accepted by customer',
                                mail: 'Mail customer',
                                rejected: 'Rejected by customer',
                                delete: 'Delete',
                                reuse: 'Reuse Margins (load all products/load all products from SuppliersSelection)',
                                duplicate_keep_margins: 'Duplicate Proposal - Keep Margins',
                                duplicate_keep_prices: 'Duplicate Proposal - Keep Prices',
                                repopulate: 'Repopulate Products'
                            }
                        }
                    }
                }
            },
            create: {
                select_client: {
                    title: "New proposal",
                    list: {
                        header: 'Choose customer',
                        content: {
                            client_number: 'Customer number {number}'
                        },
                        menu: {
                            search: 'Search for customer'
                        }
                    },
                    sidebar: {
                        label: 'or Create new customer',
                        button: 'New Customer'
                    }
                },
                notifications: {
                    save_success: 'Proposal has been saved successful',
                    update_success: 'Proposal has been updated successful',
                    save_error: 'There was a problem creating a proposal',
                }
            },
            starting: {
                setup: 'Set up starting Gross Margin',
                setup_desc: 'Set up Gross Margin value for all fields to start working with the proposal.',
                gross_value: 'Gross Margin Value'
            },
            header: {
                title: 'Proposal',
                customer: 'Customer number'
            },
            status: {
                draft: 'Draft',
                archived: 'Archived',
                awaiting: 'Awaiting acceptance',
                accepted: 'Accepted by the customer',
                rejected: 'Rejected'
            },
            tabs: {
                outbound: 'Outbound',
                returns: 'Returns',
                surcharges: 'Surcharges',
                content: 'Content'
            },
            outbound: {
                header: {
                    title: 'Sales price'
                },
                add_country: 'Add country',
                send_client: 'Send to the customer',
                display_list: 'only display weight brackets used by this list'
            }
        }
    }
}


const numberFormats = {
    'en': {
        decimal: {
            style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2
        },
    }
}

const i18n = createI18n({
    locale: 'en',
    messages,
    numberFormats
})

export default i18n;
