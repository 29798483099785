import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_outline_button = _resolveComponent("outline-button")!
  const _component_proposal_suppliers_form = _resolveComponent("proposal-suppliers-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_outline_button, {
      type: "outline",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createTextVNode(" ☰ " + _toDisplayString(_ctx.$t('proposal.suppliers.button')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_modal, {
      modelValue: _ctx.modalVisibility,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalVisibility) = $event)),
      "buttons-position": "right",
      "prevent-close": "true",
      size: "tall",
      onConfirmClick: _ctx.loadPrices
    }, {
      title: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('proposal.suppliers.modal_form.title')), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_proposal_suppliers_form)
      ]),
      "confirm-button": _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('proposal.suppliers.modal_form.apply')), 1)
      ]),
      _: 1
    }, 8, ["modelValue", "onConfirmClick"])
  ], 64))
}