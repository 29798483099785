
import { defineComponent, PropType } from 'vue';
import DropdownMenu, { Action } from '@/components/ui/DropdownMenu.vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';
import { ProposalStatus } from '@/model/proposal-status';
import { ProposalCustomer } from '@/model/proposal-customer';
import { ProposalExportType } from '@/model/proposal-export-type';
import { ProposalAction } from '@/store/proposal/proposal.action';

// TODO: ACTIONS

export default defineComponent({
  name: "ProposalRowActions",
  components: {OutlineButton, DropdownMenu},
  props: {
    proposal: {
      type: Object as PropType<ProposalCustomer>,
      required: true
    }
  },
  computed: {
    actions(): Action[] {
      switch (this.proposal.status.toLowerCase()) {
        case ProposalStatus.DRAFT:
          return [
            {
              id: 'send',
              icon: 'send-action',
              label: 'proposal.list.content.table.body.actions.send',
              separate: true,
              type: undefined,
              action: (): void => {
                this
                    .$store
                    .dispatch(ProposalAction.CHANGE_STATUS, {
                      proposalId: this.proposal.id,
                      status: ProposalStatus.AWAITING
                    })
                    .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
              }
            },
            this.archiveAction,
            {
              id: 'delete',
              icon: 'close',
              label: 'proposal.list.content.table.body.actions.delete',
              separate: false,
              type: 'danger',
              action: (): void => {
                this.$emit('proposalDelete');
              }
            }
          ];
        case ProposalStatus.AWAITING:
          return [
            {
              id: 'accepted',
              icon: 'accepted',
              label: 'proposal.list.content.table.body.actions.accepted',
              separate: false,
              type: undefined,
              action: (): void => {
                this
                    .$store
                    .dispatch(ProposalAction.CHANGE_STATUS, {
                      proposalId: this.proposal.id,
                      status: ProposalStatus.ACCEPTED
                    })
                    .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
              }
            },
            this.draftAction,
            // {
            //   id: 'mail',
            //   icon: 'mail',
            //   label: 'proposal.list.content.table.body.actions.mail',
            //   separate: false,
            //   type: undefined,
            //   action: (): void => {

            // }
            // },
            {...this.pdfAction, separate: false},
            {...this.xlsAction, separate: true},
            this.archiveAction,
            {
              id: 'rejected',
              icon: 'reject',
              label: 'proposal.list.content.table.body.actions.rejected',
              separate: false,
              type: 'danger',
              action: (): void => {
                this
                    .$store
                    .dispatch(ProposalAction.CHANGE_STATUS, {
                      proposalId: this.proposal.id,
                      status: ProposalStatus.REJECTED
                    })
                    .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
              }
            },
          ];
        case ProposalStatus.ACCEPTED:
        case ProposalStatus.REJECTED:
          return [
            this.duplicateKeepMarginsAction,
            this.duplicateKeepPricesAction,
            this.draftAction,
            this.pdfAction,
            {...this.xlsAction, separate: true},
            this.archiveAction,
          ];
        case ProposalStatus.ARCHIVED:
          return [
            this.draftAction,
            this.duplicateKeepMarginsAction,
            this.duplicateKeepPricesAction,
          ]
        default:
          return [];
      }
    },
    pdfAction(): Action {
      return {
        id: 'pdf',
        icon: 'pdf',
        label: 'proposal.list.content.table.body.actions.pdf',
        separate: false,
        type: undefined,
        action: (): void => {
          this.$store.dispatch(ProposalAction.EXPORT_PROPOSAL, {
            proposalId: this.proposal.id,
            type: ProposalExportType.PDF
          })
        }
      }
    },
    draftAction(): Action {
      return {
        id: 'draft',
        icon: 'edit',
        label: 'proposal.list.content.table.body.actions.draft',
        separate: false,
        type: undefined,
        action: (): void => {
          this
              .$store
              .dispatch(ProposalAction.CHANGE_STATUS, {
                proposalId: this.proposal.id,
                status: ProposalStatus.DRAFT
              })
              .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
        }
      }
    },
    xlsAction(): Action {
      return {
        id: 'xls',
        icon: 'xls',
        label: 'proposal.list.content.table.body.actions.xls',
        separate: false,
        type: undefined,
        action: (): void => {
          this.$store.dispatch(ProposalAction.EXPORT_PROPOSAL, {
            proposalId: this.proposal.id,
            type: ProposalExportType.XLS
          })
        }
      }
    },
    duplicateKeepMarginsAction(): Action {
      return {
        id: 'duplicate-keep-margins',
        icon: 'percent',
        label: 'proposal.list.content.table.body.actions.duplicate_keep_margins',
        separate: false,
        type: undefined,
        action: (): void => {
          this.$router.push({
            name: 'proposal.form.outbound',
            params: {clientId: this.proposal.customer.id},
            query: {reuse: this.proposal.id, loadAllProducts: 'false'}
          });
        }
      }
    },
    duplicateKeepPricesAction(): Action {
      return {
        id: 'duplicate-keep-prices',
        icon: 'coin-dollar',
        label: 'proposal.list.content.table.body.actions.duplicate_keep_prices',
        separate: false,
        type: undefined,
        action: (): void => {
          this.$router.push({
            name: 'proposal.form.outbound',
            params: {clientId: this.proposal.customer.id},
            query: {reuse: this.proposal.id, loadAllProducts: 'false', keepSalePrices: 'true'}
          });
        }
      }
    },
    archiveAction(): Action {
      return {
        id: 'archive',
        icon: 'archive',
        label: 'proposal.list.content.table.body.actions.archive',
        separate: false,
        type: undefined,
        action: (): void => {
          this
              .$store
              .dispatch(ProposalAction.CHANGE_STATUS, {
                proposalId: this.proposal.id,
                status: ProposalStatus.ARCHIVED
              })
              .then(() => this.$store.dispatch(ProposalAction.FETCH_LIST_ELEMENTS))
        }
      }
    }
  },
})
