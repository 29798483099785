
import { defineComponent } from 'vue';
import OutlineButton from '@/components/ui/OutlineButton.vue';

export default defineComponent({
  name: "Modal",
  components: {OutlineButton},
  emits: ['confirmClick', 'update:modelValue'],
  props: {
    modelValue: {
      type: Boolean
    },
    buttonsPosition: {
      type: String,
      default: 'left'
    },
    type: {
      type: String,
      default: ''
    },
    hideCancelButton: {
      type: Boolean,
      default: false
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    preventClose: {
      type: String,
      default: 'false'
    },
    blockConfirm: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleConfirmClick(): void {
      this.$emit('confirmClick');

      if (this.preventClose == "true") {
        this.$emit('update:modelValue', true);
      } else {
        this.$emit('update:modelValue', false);
      }
      // this.$emit('update:modelValue', false);
    },
    close(): void {
      this.$emit('update:modelValue', false);
    }
  },
  computed: {
    buttonsClass(): string {
      switch (this.buttonsPosition) {
        case 'right':
          return 'modal__buttons--right';
        default:
          return 'modal__buttons--left';
      }
    },
    modalTypeClass(): string {
      switch (this.type) {
        case 'warning':
          return 'modal--warning';
        case 'confirm':
          return 'modal--confirm';
        default:
          return '';
      }
    },
    modalSize(): string {
      switch (this.size) {
        case 'big':
          return 'modal--big';
        case 'tall':
          return 'modal--tall';
        default:
          return '';
      }
    }
  }
})
