import { Supplier } from '@/model/supplier';

export enum SupplierFields {
    suppliers = 'supplierIds',
    countries = 'countryIds',
    products = 'productNames',
    contracts = 'contractNames',
    services = 'serviceNames',
    previousYear = 'previousYear',
}

export type StringifySupplierParams = {
    [key in SupplierFields]?: string
};

export type SupplierParams = {
    [key in SupplierFields]?: string[];
};

export type SuppliersSelectOption = {
    id: string;
    name: string;
};

export type SuppliersSection = {
    params: SupplierParams;
    availableOptions: {
        [key in SupplierFields]?: SuppliersSelectOption[];
    };
};

export interface SuppliersChoices {
    availableSuppliers: Supplier[];
    suppliers: SuppliersSection[];
}

export type SuppliersSectionSavingData = {
    index: number;
    values: SupplierParams;
}