import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d75a0a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proposal-content relative" }
const _hoisted_2 = { class: "proposal-content__heading flex justify-between items-center" }
const _hoisted_3 = { class: "proposal-content__sections" }
const _hoisted_4 = { class: "font-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_content_loader = _resolveComponent("content-loader")!
  const _component_paragraph_section = _resolveComponent("paragraph-section")!
  const _component_content_section = _resolveComponent("content-section")!
  const _component_section_button = _resolveComponent("section-button")!
  const _component_edit_paragraph_modal = _resolveComponent("edit-paragraph-modal")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isProposalLoading)
      ? (_openBlock(), _createBlock(_component_content_loader, {
          key: 0,
          label: "general.loading"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t('proposal.content.heading')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_content_section, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.intro_text')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_paragraph_section, {
            "mutation-type": _ctx.introTextMutation,
            paragraph: _ctx.introText,
            key: _ctx.introText.content.length
          }, null, 8, ["mutation-type", "paragraph"]))
        ]),
        _: 1
      }),
      _createVNode(_component_content_section, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.general_disclaimer')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_paragraph_section, {
            "mutation-type": _ctx.generalDisclaimerMutation,
            paragraph: _ctx.generalDisclaimer,
            key: _ctx.generalDisclaimer.content.length
          }, null, 8, ["mutation-type", "paragraph"]))
        ]),
        _: 1
      }),
      _createVNode(_component_content_section, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.disclaimers')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_paragraph_section, {
            "mutation-type": _ctx.disclaimersMutation,
            paragraph: _ctx.disclaimers,
            key: _ctx.disclaimers.content.length
          }, null, 8, ["mutation-type", "paragraph"]))
        ]),
        _: 1
      }),
      _createVNode(_component_content_section, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.terms_conditions')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_paragraph_section, {
            "mutation-type": _ctx.termsMutation,
            paragraph: _ctx.terms,
            key: _ctx.terms.content.length
          }, null, 8, ["mutation-type", "paragraph"]))
        ]),
        _: 1
      }),
      _createVNode(_component_content_section, null, {
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.gdpr')), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_paragraph_section, {
            "mutation-type": _ctx.gdprMutation,
            paragraph: _ctx.gdpr,
            key: _ctx.gdpr.content.length
          }, null, 8, ["mutation-type", "paragraph"]))
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paragraphs, (paragraph, index) => {
        return (_openBlock(), _createBlock(_component_content_section, {
          key: `paragraph-${index}`,
          "show-remove-button": true,
          onRemoveSectionClick: ($event: any) => (_ctx.handleRemoveSectionClick(paragraph))
        }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.sections.paragraph', {number: index + 1})), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_paragraph_section, {
              "mutation-type": _ctx.newParagraphMutation,
              paragraph: paragraph,
              key: paragraph.content.length
            }, null, 8, ["mutation-type", "paragraph"]))
          ]),
          _: 2
        }, 1032, ["onRemoveSectionClick"]))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      (_ctx.isProposalEditable)
        ? (_openBlock(), _createBlock(_component_section_button, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showAddModal = true))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('proposal.content.add_paragraph')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_modal, {
        modelValue: _ctx.showAddModal,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showAddModal) = $event)),
        onConfirmClick: _ctx.handleConfirmClick
      }, {
        title: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('proposal.content.paragraph.add')), 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_edit_paragraph_modal, {
            title: _ctx.newParagraph.title,
            "onUpdate:title": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newParagraph.title) = $event)),
            content: _ctx.newParagraph.content,
            "onUpdate:content": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newParagraph.content) = $event))
          }, null, 8, ["title", "content"])
        ]),
        _: 1
      }, 8, ["modelValue", "onConfirmClick"])
    ])
  ]))
}