import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full flex flex-col justify-between" }
const _hoisted_2 = { class: "text-light font-bold my-3" }
const _hoisted_3 = {
  key: 0,
  class: "text-light my-3"
}
const _hoisted_4 = { class: "h-10 my-3 px-0.5" }
const _hoisted_5 = {
  key: 1,
  class: "pt-text-light"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_percent_input = _resolveComponent("percent-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$te(`general.currency_symbol.${_ctx.weightBracket.price.currency}`) ? _ctx.$t(`general.currency_symbol.${_ctx.weightBracket.price.currency}`) : `${_ctx.weightBracket.price.currency}`) + " " + _toDisplayString(_ctx.price), 1),
    (_ctx.showCostPrice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$te(`general.currency_symbol.${_ctx.weightBracket.price.currency}`) ? _ctx.$t(`general.currency_symbol.${_ctx.weightBracket.price.currency}`) : `${_ctx.weightBracket.price.currency}`) + " " + _toDisplayString(_ctx.costPrice), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.showGrossMargin && _ctx.isProposalEditable)
        ? (_openBlock(), _createBlock(_component_percent_input, {
            key: 0,
            modelValue: _ctx.grossMargin,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.grossMargin) = $event)),
            disabled: _ctx.isDisabled,
            changed: _ctx.grossMarginMeta.dirty,
            valid: _ctx.grossMarginMeta.valid
          }, null, 8, ["modelValue", "disabled", "changed", "valid"]))
        : _createCommentVNode("", true),
      (_ctx.showGrossMargin && !_ctx.isProposalEditable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.grossMargin) + "; ", 1))
        : _createCommentVNode("", true)
    ])
  ]))
}